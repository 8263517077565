/***********************************************************************************************************************
 * Required overwrites
 **********************************************************************************************************************/
@import "Variables";
@import "Switch";


$btn-close-cc-bg: #000;
$btn-close-cc-color: #fff;

$btn-open-cc-bg: #000;
$btn-open-cc-color: #fff;

/***********************************************************************************************************************
 *
 * Required overwrites
 **********************************************************************************************************************/
.fadeable {
  transition: all 0.2s ease-in-out 0s;
  opacity:    0;
  visibility: hidden;
}

.fadeable.show {
  opacity:    1;
  visibility: visible;

}



.fancybox-enabled.fancybox-cookiebanner-enabled {
    overflow: auto;
}

[data-cc-popup-trigger] {
  position:       relative;
  text-transform: uppercase;
    cursor: pointer;
  /*bottom: 0;
  left: 3rem;
  z-index: 200;*/
}


.rounded-bottom-0 {
  border-bottom-left-radius:  0;
  border-bottom-right-radius: 0;
}

.td-min {
  width:       1px;
  //white-space: nowrap;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}


.MuiButton-label {
    overflow: visible;
}
.MuiButton-text:not(.MuiButton-root) .MuiButton-label:after {
                                         top: 50%;
    transform: translateY(-50%);
                                     }

.fancybox-cookiebanner-popup {
    max-width: 480px !important;
}
.fancybox-cookiebanner {
    z-index: 1000 !important;
    padding-top: 0;
    left: 0 !important;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    height: auto !important;
    width: auto;
    display: inline-block;

    .MuiButton-text {

     text-transform: uppercase;
        display: inline-block;
        &:hover {
            text-decoration: none;
        }
        > span:not(.MuiButton-label) {
            &:before {
content: "";
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background: #000;
            }
        }
    }


    .fancybox-slider,
    .fancybox-slider-wrap {
        position: static !important;
    }

    .fancybox-caption,
    .fancybox-caption-wrap {
        display: none !important;
    }

    .fancybox-slide {
        left: auto!important;
        right: auto!important;
        bottom: auto!important;
        top: auto!important;
        position: static!important;
        height: auto !important;
        padding: 0;
    }
    .fancybox-slide>* {
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    .modal-dialog {
        margin: 0 !important;
    }
    .modal-content {
        padding: 0 !important;
    }
    .modal {
        position: static !important;
        max-height: 100vh;
        overflow: auto;
    }

}
.fancybox-cookiebannersss {
  overflow: visible;
    .fancybox-slide {

        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.26);
        padding-top: 0;
        left:        0 !important;
        top:         auto !important;
        right:       0 !important;
        bottom:      0 !important;
        height:      auto !important;
        width:       auto;
        display:     inline-block;
    }




    .modal-dialog {
        max-height: calc(100vh - 10px);
    }


    .fancybox-stage {
        overflow: visible;
    }


    .fancybox-content {
        padding:    25px 25px 5px 5px;
        background: transparent;
        overflow:   auto;
    }


    .modal-dialog {
        margin: 0;
    }


    .modal {
        bottom:   0;
        display:  inline-block;
        position: relative;
    }



    .fancybox-slide {
        padding:    0;

        background: #fff;
    }


    .fancybox-slide--html {
        padding: 0;
    }


    .fancybox-bg,
    .fancybox-inner,
    .fancybox-outer,
    .fancybox-slide,
    .fancybox-stage {
        left:     auto !important;
        right:    auto !important;
        bottom:   auto !important;
        top:      auto !important;
        position: static !important;
    }


    .fancybox-content {
        margin-left:  auto;
        margin-right: auto;
        overflow:     visible;
    }


    .modal {
        max-height:   100vh;
        margin-left:  auto;
        margin-right: auto;
        overflow:     visible;
    }

}

/***********************************************************************************************************************
 * custom styles
 **********************************************************************************************************************/




@import "Theme";

.cc-popup-trigger-container {
    display: block;
    pointer-events: none;
    bottom: 0;
    position: fixed;
    z-index: 100;
    width: 100%;
    left: 50%;
    max-width: 1200px;
    padding-left: 16px;
    transform: translateX(-50%);
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

[data-cc-popup-trigger] {
    font-size: 14px;
    float: right;
    font-weight: 500;
    letter-spacing: 1px;
    pointer-events: all;
    color: $btn-open-cc-color;
    background-color: $btn-open-cc-bg;
    padding: .5em 1em;
    border: 0 !important;
    border-radius: 0;
}
[data-cc-popup-trigger]:hover {
    background: $btn-open-cc-bg;
    color: $btn-open-cc-color;
}
[data-cc-popup-trigger].focus, [data-cc-popup-trigger]:focus {
    box-shadow: 0 0 0 0.2rem rgba($btn-open-cc-bg, .5);
}

.fancybox-cookiebanner .btn-primary{
    background: #000;
    color: #ffffff;
    font-weight: bold;
    padding: 0.5rem 1rem;
}

body.compensate-for-scrollbar {
    margin-right: 0;
    overflow-x: hidden;
    overflow-y: auto;
}


.fancybox-cookiebanner {
    hr {
        display: block;
        margin: 2rem 0 1rem 0;
        padding: 0;
        border: 0;
        border-bottom: 1px solid rgba(147,160,162,.5);
        width: 100%;
    }

    #cookie-banner-details {
        max-width: 100%;
    }

    .modal {
        padding: 0;
        box-shadow: none;
    }
    .modal-header {
        margin-bottom: .5rem;
    }
    .modal-content {
        padding-bottom: 20px;
    }
    a {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
        &.btn-primary {
            &:hover {
                text-decoration: none;
            }
        }
    }
    p {
        margin-bottom: 1em;
        font-size: 14px;
        color: #4A4A4A;
        letter-spacing: 0;
        line-height: 1.429em;
    }

    .btn-container {
        @media (min-width: 768px) {

            padding-top: 5px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            .btn-group {
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
            }
        }
    }
    .btn-primary-chevron {
        padding-right: 2.5rem;
    }

}



.fancybox-cookiebanner .modal-header {
    padding-top: 20px;
}
.fancybox-cookiebanner .modal-header,
.fancybox-cookiebanner .modal-body,
.fancybox-cookiebanner .modal-footer,
.tab-content>.tab-pane .tab-content>.tab-pane {
    padding-left: 20px;
    padding-right: 20px;
}
.tab-content>.tab-pane .tab-content>.tab-pane {
    padding-top: 20px;
    padding-bottom: 20px;
}

.fancybox-cookiebanner .modal-content {
    border-color: #EAEAEA;
}
.fancybox-cookiebanner .fancybox-content {
    max-width: 1240px;
}
.fancybox-cookiebanner .fancybox-content:not([style*="hidden"]) {
    display: block !important;
}

.fancybox-cookiebanner .modal-header {
    border-bottom: 0;
}
.fancybox-cookiebanner .modal-footer {
    border-top: 0;
}


// $btn-close-cc-color
.fancybox-cookiebanner .fancybox-close-small {
    display: block;
    background: $btn-close-cc-bg;
    border-radius: 0px;
    opacity: 1;
    padding: 2px;
    z-index: 1100;
    margin-top: 0;
    margin-right: 0;
    right: 0px;
    top: 0px;
    &:after {
        background: $btn-close-cc-bg;
        color: $btn-close-cc-color;
        content: '';
        width: 44px;
        height: 44px;
        top: 0;
        right: 0;
        background: transparent url("/typo3conf/ext/cookieconsent/Resources/Public/assets/img/close.svg") no-repeat 50% 50%;
        background-size: contain;
    }
}

.fancybox-cookiebanner .btn-toolbar .btn-group .btn,
.fancybox-cookiebanner .btn-toolbar .btn-group .btn:focus {
    background: transparent;
    outline: 0;
    cursor: pointer;
    box-shadow: none !important;
}

.fancybox-cookiebanner .fancybox-close-small path {
    fill: #fff;
}

.fancybox-cookiebanner .nav-tabs {
    border-bottom-color: #979797;
}

.fancybox-cookiebanner .nav-pills .nav-link {
    color: #000;
    padding-top: 15px;
    padding-bottom: 15px;
}

.fancybox-cookiebanner .nav-tabs .nav-item{
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
    color: #000;
}
.fancybox-cookiebanner .nav-tabs .nav-link,
.fancybox-cookiebanner .nav-pills .nav-link{
    font-size: 15px;
}
.fancybox-cookiebanner .nav-tabs .nav-link.active,
.fancybox-cookiebanner .nav-tabs .nav-item.show .nav-link {
    color: #000;
}

.fancybox-cookiebanner .badge {
    width: 30px;
    display: inline-block;
    height: 30px;
    position: relative;
    background: #003064;
    border-radius: 999px;
    margin-left: 10px;
}
.fancybox-cookiebanner .badge .badge-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

.fancybox-cookiebanner .table .thead-light th {
    border-top: 0;
    border-bottom-width: 1px;
    opacity: 1  ;
    font-family: Helvetica;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 300;
    background: #eceeee;
}

.fancybox-cookiebanner .table-sm th,
.fancybox-cookiebanner .table-sm td {
    border-bottom-color: rgba(151, 151, 151, 0.5);
    padding: 1em !important;
    font-size: 14px;
}
.fancybox-cookiebanner .table-sm th {
    padding-top: .5em !important;
    padding-bottom: .5em !important;
}

@media (min-width: 768px) {
    .fancybox-cookiebanner .tab-content>.tab-pane {
        overflow: auto;
    }
}

.fancybox-cookiebanner .nav-pills .nav-link.active,
.fancybox-cookiebanner .nav-pills .show>.nav-link {
    background: #fff;
}


.fancybox-cookiebanner .show>.btn-primary.dropdown-toggle {
    background-color: #E2001A !important;
    border-color: #E2001A;
}
.fancybox-cookiebanner a.btn-primary {
    margin: 0;
}
.fancybox-cookiebanner [data-cc-save-trigger] {
    margin: 0 !important;
}


.fancybox-cookiebanner .btn-primary-chevron {
    position: relative;
    background-image: none !important;
}

.fancybox-cookiebanner .btn-primary-chevron svg {
    position: absolute;
    right: 4px;
    top: 50%;
    display: block !important;
    transform: translate(-50%,-50%) rotate(180deg);
    transition: all .3s ease-in-out 0s;
}

.fancybox-cookiebanner .btn-primary-chevron.active svg {
    transform: translate(-50%,-50%);
}


.fancybox-cookiebanner {
    [data-cc-trigger-details] {
        [data-cc-trigger-details-inactive] { display: inline; }
        [data-cc-trigger-details-active] { display: none; }
        &.active {
            [data-cc-trigger-details-inactive] { display: none; }
            [data-cc-trigger-details-active] { display: inline; }
        }
    }
}







.fancybox-cookiebanner .btn-primary {
    display: inline-block;
}

.fancybox-cookiebanner .content A.btn-primary[data-cc-save-trigger] {
    transition: background-color 200ms ease-out, color 200ms ease-out;
    background-image: url(/typo3conf/ext/durchstarten/Resources/Public/Svg/ico-half-arrow-white.svg);
    color: #fff;
}



.fancybox-cookiebanner .fancybox-content {
  padding:    0;
  background: transparent;
  overflow:   auto;
}



/* Nav tab */
.fancybox-cookiebanner {
    * {
        max-width: 100%;
    }
    .nav-tabs {
        margin-top: 2rem;
        display: block;
        border-bottom: 1px solid rgba(147, 160, 162,.5);
        //979797;
    }
    .nav-item {
        display: inline-block;
    }
    .nav-link {
        display: inline-block;
        background: transparent;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 1.429em;
        font-weight: 700;
        padding: .5rem 1rem;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        &.active {
            background: #000;
            color: #fff !important;
        }
    }
    .tab-pane {
        display: none;
        padding: 20px;
        &.show {
            display: block;
        }
    }
    .tab-inner-nav {
        width: 300px;
    }
    .tab-inner-content {
        width: 100%;
    }
    .details-container {
        border: 1px solid rgba(147, 160, 162,.5);
        border-top: 0;
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
        .nav-link {
            color: #4a4a4a;
            &.active {
                background: #000;
                color: #fff !important;
            }
        }

    }
    .nav-tabs-level-2 {
        .nav-link {
            padding: 8px 13px;
            border-radius: 3px;
        }
    }
    .tab-content .tab-content {
        .tab-pane {
            padding-top: 0;
        }
    }
}
@media (max-width: 768px) {
    .fancybox-cookiebanner {

        .tab-inner-nav {
            float: none;
            width: 100%;
        }
        .tab-inner-content {
            float: none;
            width: 100%;
        }
    }
}